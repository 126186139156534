import { Button, Col, Form, Input, Row, Select, Space, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { countryCodes } from 'src/utils/country-codes';
import { useMutation } from '@tanstack/react-query';
import api from 'src/api';
import toast from 'react-hot-toast';
import { IoDocumentOutline } from 'react-icons/io5';

const media = {
  logo: '/assets/images/logo.png',
  sideImg: '/assets/images/register-side-img.png'
};

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
`;

const RightContent = styled.div`
  width: 100%;
  display: none;

  @media (min-width: ${prop => prop.theme.antd.screenLG}px) {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LeftContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${prop => prop.theme.antd.screenLG}px) {
    width: 50%;
  }
`;

const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;

  @media (min-width: ${prop => prop.theme.antd.screenMDMin}px) {
    max-width: ${prop => prop.theme.antd.screenSM}px;
  }
`;

const OtpVerification = () => {
  const navigate = useNavigate();
  const inputRef = useRef(null); // Initialize ref to null
  const location = useLocation();

  // Accessing the state from the location object
  const { state } = location;

  // Logging the state to the console
  console.log({ state });

  const verify = useMutation({
    mutationFn: async data => {
      try {
        const response = await api.put('/vendor/auth/phone', {
          ...data,
          phone: state?.phone,
          countryCode: state?.countryCode
        });
        toast.success('Verification Link Sent To Your Email');
        navigate('/auth/verify');
      } catch (error) {
        console.log({ error });
        error = error?.response?.data;
        if (!(error?.message === 'validation error')) {
          toast.error(error?.message ?? 'Something went wrong');
        }
        const firstError = Object.keys(error?.errors || {});
        if (firstError.length > 0) {
          toast.error(error?.errors[firstError[0]]);
        }
      }
    },
    onError: err => {
      console.log({ err });
      err = err?.response?.data;
      if (!(err?.message === 'validation error')) {
        toast.error(err?.message ?? 'Something went wrong');
      }
      const firstError = Object.keys(err?.errors || {});
      if (firstError.length > 0) {
        toast.error(err?.errors[firstError[0]]);
      }
    }
  });

  return (
    <ContentWrapper>
      <LeftContent>
        <Container>
          <img src={media.logo} alt="Logo" style={{ marginBottom: '2rem' }} />
          <Typography.Title level={2}>Verify OTP</Typography.Title>
          <Form
            layout="vertical"
            style={{ marginTop: '1.5rem' }}
            id="registration-form"
            onFinish={verify.mutate}
            initialValues={{ countryCode: '+91' }}
          >
            <Form.Item
              label="OTP"
              name="otp"
              style={{ margin: '50px 0' }}
              rules={[
                {
                  required: true,
                  message: 'OTP is required'
                },
                {
                  pattern: /^\d{6}$/,
                  message: 'OTP must be a 6-digit number'
                }
              ]}
            >
              <Input maxLength={6} size="large" placeholder="6 digit OTP" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block
                form="registration-form"
              >
                Verify OTP
              </Button>
            </Form.Item>
          </Form>
          {/* <Typography.Paragraph style={{ textAlign: 'center' }}>
            Already have an account? <Link to="/auth/login">Log in</Link>
          </Typography.Paragraph> */}
        </Container>
      </LeftContent>
      <RightContent>
        <Container>
          <img src={media.sideImg} alt="Side" />
        </Container>
      </RightContent>
    </ContentWrapper>
  );
};

export default OtpVerification;
