import { GoCheckCircleFill } from 'react-icons/go';
import { HiLockClosed } from 'react-icons/hi';
import { useSearchParams } from 'react-router-dom';
import { useCalendarEntries } from 'src/hooks/calendar-entries.queries';
import Spinner from './spinner';
import d from 'dayjs';
import { FaCalendar } from 'react-icons/fa6';
import { useQuery } from '@tanstack/react-query';
import api from 'src/api';
import { useEffect, useState } from 'react';

function DayCard({
  selectedDate,
  setSelectedDate,
  from,
  date,
  month,
  year,
  roomId,
  room
}) {
  const [searchParams] = useSearchParams();
  const propertyId = searchParams.get('propertyId');
  const isPast =
    d() > d().set('date', date).set('month', month).set('year', year);
  const [status, setStatus] = useState('');
  const [day, setDay] = useState({});

  const { data: property } = useQuery({
    queryKey: ['property', propertyId, ['propertyName', 'propertyType']],
    queryFn: async ({ queryKey }) => {
      const { data: { property = {} } = {} } = await api.get(
        `/properties/find/${propertyId}?select=${queryKey?.[2]?.join?.(' ')}`
      );
      return property;
    }
  });

  const { error, isFetching, calendarEntries } = useCalendarEntries({
    from: date ? from.set('date', date) : from,
    propertyId,
    roomId
  });

  // const { data: rooms } = useQuery({
  //   queryKey: ['distinct-rooms', propertyId],
  //   enabled: property?.propertyType === 'hotel',
  //   queryFn: async () => {
  //     const { data: { rooms = [] } = {} } = await api.get(
  //       `/hotel-rooms/${propertyId}/distinct?select=roomName`
  //     );
  //     const hotelRooms = {};
  //     rooms.forEach(room => {
  //       hotelRooms[room.roomTypeId] = room;
  //     });
  //     return hotelRooms;
  //   }
  // });

  useEffect(() => {
    if (property) {
      let day = {};
      let status = '';

      if (property.propertyType === 'hotel' && room) {
        if (calendarEntries.length) {
          status =
            calendarEntries[0].roomCount === room.count
              ? 'blocked'
              : `${room.count - calendarEntries[0].roomCount} left to sell`;
        } else {
          status = `${room.count} left to sell`;
        }
      } else {
        day = calendarEntries?.[0] ?? {};
        status = day?.isBlocked ? 'blocked' : 'Available';
      }

      setStatus(status);
      setDay(day);
    }
  }, [property, calendarEntries, room]);

  console.log(room);

  return (
    <div
      onClick={() => {
        if (!error && !isFetching) {
          setSelectedDate({ _id: day?._id, date });
        }
      }}
      style={{height: "140px", aspectRatio: "0/0"}}
      className={` __calender_day 
   --${status} 
   ${selectedDate?.date === date && '--selected'}
   ${isPast && '--past'} selectable
   `}
      key={'day-' + date}
    >
      {error ? (
        'error'
      ) : isFetching ? (
        <Spinner />
      ) : (
        <>
          <p style={{ fontWeight: 600, color: 'black' }}>{date}</p>
          <p style={{ textAlign: 'center' }}>
            {status === 'blocked' ? (
              <HiLockClosed size={24} />
            ) : status === 'booked' ? (
              <GoCheckCircleFill size={24} />
            ) : (
              <FaCalendar color="#c3c3c3" size={24} />
            )}
          </p>
          {!isPast && (
            <p
              style={{
                textAlign: 'center',
                textTransform: 'capitalize',
                alignSelf: 'center',
                fontWeight: 500
                // ...(status === 'available' && { marginBottom: 'auto' })
              }}
            >
              {status}
            </p>
          )}
          {/* {status === 'available' && (
            <p style={{ fontWeight: 600, textAlign: 'end' }}>r</p>
          )} */}
        </>
      )}
    </div>
  );
}

export default DayCard;
