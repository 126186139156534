import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './index.css';
import { APIProvider } from '@vis.gl/react-google-maps';
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } }
});

root.render(
  <BrowserRouter>
    <APIProvider
      apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
      libraries={['geocoding']}
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </APIProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
