import { create } from 'zustand';

export const useUserStore = create(set => ({
  isFetching: true,
  user: null,
  local: {
    currency: 'MAD',
    country: 'Morocco',
    currencySymbol: 'د.م.'
  },
  setUser: user => set({ user, isFetching: false }),
  logoutUser: () => set({ user: null }),
  setIsFetching: isFetching => set({ isFetching }),
  setLocal: local => set({ local })
}));
